<template>
  <div>
    <v-row
      align="center"
      justify="space-between"
      class="pb-0 poppins"
      style="height: 110px"
    >
      <v-col cols="12" md="5" class="p-0 m-0 mr-3">
        <p class="font-size-lg font-weight-medium mb-0">{{ label }}</p>
      </v-col>
      <v-col
        class="p-0 m-0 d-flex justify-content-end"
        style="border: 1px solid rgb(220, 223, 232); border-radius: 7px"
      >
        <slot></slot>
      </v-col>
    </v-row>
    <v-row class="p-0 p-0" style="margin-top: -20px">
      <v-col v-if="divider" cols="12" class="p-0 m-0">
        <div class="separator separator-dashed"></div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "FieldContainer",
  props: {
    label: {
      required: true,
      type: String,
      default: "Default label, please add your own",
    },
    divider: {
      required: false,
      type: Boolean,
      default: true,
    },
  },
};
</script>
