<template>
  <v-container class="d-flex flex-column flex-fill ml-2 mr-4">
    <v-row>
      <v-col cols="12" lg="8">
        <FieldContainer v-if="serverData.organizations" label="Organization">
          <v-autocomplete
            v-model="formData.organization_id"
            clearable
            dense
            outlined
            class="rounded-lg h-50px"
            required
            item-text="text"
            item-value="index"
            :items="serverData.organizations"
            :error-messages="organizationsErrors"
            @blur="$v.formData.organization_id.$touch()"
            @input="$v.formData.organization_id.$touch()"
          ></v-autocomplete>
        </FieldContainer>

        <FieldContainer label="Client type">
          <v-autocomplete
            v-model="formData.type_id"
            clearable
            dense
            outlined
            class="rounded-lg h-50px"
            required
            :items="serverData.client_types"
            item-text="text"
            item-value="index"
            :error-messages="typeErrors"
            @blur="$v.formData.type_id.$touch()"
            @input="$v.formData.type_id.$touch()"
          />
        </FieldContainer>

        <FieldContainer label="Aggregator" v-if="formData.type_id == 1">
          <v-autocomplete
            dense
            outlined
            class="rounded-lg h-50px"
            clearable
            v-model="formData.aggregator_id"
            item-text="text"
            item-value="index"
            :items="serverData.aggregators"
            :error-messages="aggregatorErrors"
            @blur="$v.formData.aggregator_id.$touch()"
            @input="$v.formData.aggregator_id.$touch()"
          />
        </FieldContainer>

        <FieldContainer label="Name">
          <v-text-field
            dense
            outlined
            class="rounded-lg h-50px"
            clearable
            v-model="formData.name"
            :error-messages="nameErrors"
            @blur="$v.formData.name.$touch()"
            @input="$v.formData.name.$touch()"
          />
        </FieldContainer>

        <FieldContainer label="System code">
          <v-text-field
            dense
            outlined
            class="rounded-lg h-50px"
            clearable
            v-model="formData.systemCode"
            :error-messages="systemCodeErrors"
            @blur="$v.formData.systemCode.$touch()"
            @input="$v.formData.systemCode.$touch()"
          ></v-text-field>
        </FieldContainer>

        <FieldContainer label="Email">
          <v-text-field
            dense
            outlined
            class="rounded-lg h-50px"
            clearable
            v-model="formData.email"
            :error-messages="emailErrors"
            @blur="$v.formData.email.$touch()"
            @input="$v.formData.email.$touch()"
          />
        </FieldContainer>

        <FieldContainer label="Update order status">
          <v-autocomplete
            v-model="formData.update_order_status"
            clearable
            dense
            outlined
            class="rounded-lg h-50px"
            required
            :items="serverData.statuses"
          />
        </FieldContainer>

        <DatepickerContainer label="Won Date">
          <SingleDatepicker
            position="left"
            :value="formData.won_date"
            :clearDate="cleardate"
            @on-submit="getDateRange"
            class="poppins"
          />
        </DatepickerContainer>

        <FieldContainer label="Staff account limit" :divider="true">
          <v-text-field
            dense
            outlined
            class="rounded-lg h-50px"
            clearable
            v-model="formData.staff_account_limit"
            :error-messages="staffAccountLimitErrors"
            @blur="$v.formData.staff_account_limit.$touch()"
            @input="$v.formData.staff_account_limit.$touch()"
          />
        </FieldContainer>

        <FieldContainer label="Shop name">
          <v-text-field
            v-model="formData.shopName"
            dense
            class="rounded-lg h-50px"
            required
            persistent-hint
            outlined
            clearable
            :error-messages="shopNameErrors"
            @blur="$v.formData.shopName.$touch()"
            @input="$v.formData.shopName.$touch()"
          ></v-text-field>
        </FieldContainer>

        <FieldContainer label="Shop code">
          <v-text-field
            v-model="formData.shopCode"
            dense
            class="rounded-lg h-50px"
            required
            persistent-hint
            outlined
            clearable
            :error-messages="shopCodeErrors"
            @blur="$v.formData.shopCode.$touch()"
            @input="$v.formData.shopCode.$touch()"
          ></v-text-field>
        </FieldContainer>

        <FieldContainer label="Shipping label">
          <v-text-field
            v-model="formData.shipping_label_name"
            dense
            class="rounded-lg h-50px"
            required
            persistent-hint
            outlined
            clearable
            :error-messages="shippingLabelErrors"
            @blur="$v.formData.shipping_label_name.$touch()"
            @input="$v.formData.shipping_label_name.$touch()"
          ></v-text-field>
        </FieldContainer>

        <FieldContainer label="Customer offering type">
          <v-autocomplete
            v-model="formData.offer_type_id"
            dense
            :items="serverData.client_offer_types"
            class="rounded-lg h-50px"
            required
            persistent-hint
            outlined
            clearable
            item-text="text"
            item-value="index"
          ></v-autocomplete>
        </FieldContainer>

        <FieldContainer label="Fulfillment type" :divider="true">
          <v-autocomplete
            v-model="formData.fulfillment_types"
            :items="serverData.fulfillment_types"
            clearable
            dense
            multiple
            outlined
            class="rounded-lg h-50px"
            required
            item-text="text"
            item-value="index"
          />
        </FieldContainer>

        <FieldContainer label="MRSAL code">
          <v-text-field
            v-model="formData.mrsal_code"
            clearable
            dense
            outlined
            class="rounded-lg h-50px"
          />
        </FieldContainer>

        <FieldContainer label="RVP pickup schedule modes">
          <v-autocomplete
            v-model="formData.rvp_pickup_scheduled_mode"
            :items="serverData.rvp_pickup_scheduled_modes"
            item-text="text"
            item-value="index"
            dense
            outlined
          />
        </FieldContainer>

        <FieldContainer label="Instant AWB">
          <v-switch
            class="h-55px"
            v-model="formData.instant_awb"
            inset
            dense
            :true-value="1"
            :false-value="0"
          ></v-switch>
        </FieldContainer>

        <FieldContainer label="Instant RVP AWB generation">
          <v-switch
            class="h-55px"
            v-model="formData.instant_rvp_awb"
            inset
            dense
            :true-value="1"
            :false-value="0"
          ></v-switch>
        </FieldContainer>

        <FieldContainer label="Consider base SKU price">
          <v-switch
            class="h-55px"
            v-model="formData.consider_base_sku_price"
            inset
            dense
            :true-value="1"
            :false-value="0"
          ></v-switch>
        </FieldContainer>

        <FieldContainer label="Services">
          <v-autocomplete
            v-model="formData.services"
            :items="serverData.services"
            item-text="text"
            item-value="index"
            dense
            outlined
            multiple
          />
        </FieldContainer>

        <FieldContainer label="Photo" :divider="true">
          <v-file-input
            v-model="formData.photo"
            show-size
            counter
            multiple
            dense
            outlined
            class="rounded-lg h-50px"
            clearable
            accept="image/png, image/jpeg, image/bmp"
            append-icon="mdi-camera"
            prepend-icon=""
          />
        </FieldContainer>

        <FieldContainer label="Logo">
          <v-file-input
            v-model="formData.logo"
            show-size
            counter
            multiple
            dense
            outlined
            class="rounded-lg h-50px"
            clearable
            accept="image/png, image/jpeg, image/bmp"
            append-icon="mdi-camera"
            prepend-icon=""
          />
        </FieldContainer>

        <FieldContainer label="Description">
          <v-text-field
            dense
            outlined
            class="rounded-lg h-50px"
            clearable
            v-model="formData.description"
          />
        </FieldContainer>

        <FieldContainer label="Sales person">
          <v-autocomplete
            dense
            outlined
            class="rounded-lg h-50px"
            clearable
            multiple
            v-model="formData.sales"
            :items="serverData.sales"
            item-text="text"
            item-value="index"
          />
        </FieldContainer>
        <FieldContainer label="Account manager" :divider="false">
          <v-autocomplete
            dense
            outlined
            multiple
            class="rounded-lg h-50px"
            clearable
            v-model="formData.account_managers"
            :items="serverData.account_managers"
            item-text="text"
            item-value="index"
          />
        </FieldContainer>
        <FieldContainer label="Expiry pullout threshold">
          <v-text-field
            dense
            outlined
            class="rounded-lg h-50px"
            type="number"
            v-model="formData.expiry_pullout_threshold"
          ></v-text-field>
        </FieldContainer>
      </v-col>
    </v-row>

    <v-row class="mt-10 mb-16">
      <v-col cols="12" lg="8" class="pr-0">
        <v-row class="d-flex justify-content-end">
          <v-col cols="auto">
            <button
              @click="submit"
              type="submit"
              class="btn btn-info px-5 py-3"
            >
              Submit
            </button>
          </v-col>
        </v-row></v-col
      ></v-row
    >
  </v-container>
</template>

<script>
import Swal from "sweetalert2";
import { required, email, numeric, requiredIf } from "vuelidate/lib/validators";

import { SET_ITEM_FOR_ACTION } from "@/core/services/store/main.module";

import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";
import validationMixin from "@/own/mixins/validationMixin.js";
import ApiService from "@/core/services/api.service";
import FieldContainer from "@/own/components/customers/clients/FormParts/FieldContainer.vue";
import DatepickerContainer from "@/own/components/customers/clients/FormParts/DatepickerContainer.vue";
import SingleDatepicker from "@/own/components/SingleDatepicker.vue";

export default {
  name: "BasicFormFields",
  mixins: [validationMixin, fieldValueValidation],
  components: { FieldContainer, SingleDatepicker, DatepickerContainer },
  props: ["serverData", "pageLoader", "actionItem", "refresher"],
  validations: {
    formData: {
      systemCode: { required },
      name: { required },
      shopCode: { required },
      shopName: { required },
      shipping_label_name: { required },
      email: { required, email },
      staff_account_limit: { required, numeric },
      type_id: { required },
      aggregator_id: {
        required: requiredIf(function () {
          this.formData.type_id === 1;
        }),
      },
      organization_id: {
        required: requiredIf(function () {
          return !!this.serverData.organizations;
        }),
      },
      spl_integration_type: {
        required: requiredIf(function () {
          return !!this.isOrganizationSPL;
        }),
      },
      // photo: { required },
    },
  },
  data: () => ({
    formData: {
      type_id: null,
      aggregator_id: null,
      organization_id: null,
      name: null,
      system_code: null,
      shopName: null,
      email: null,
      update_order_status: null,
      shopCode: null,
      shipping_label_name: null,
      contract_number: null,
      offer_type_id: null,
      sales: [],
      default_warehouse_id: null,
      photo: null,
      logo: null,
      description: null,
      fulfillment_types: [],
      rvp_pickup_scheduled_mode: 0,
      mrsal_code: null,
      instant_awb: false,
      instant_rvp_awb: false,
      consider_base_sku_price: false,
      systemCode: null,
      won_date: null,
      sales_person: null,
      account_managers: null,
      services: [],
      expiry_pullout_threshold: 60,
    },
  }),
  methods: {
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.pageLoader(true);

        let url = `/authorizations/clients/${
          this.actionItem ? `update` : `store`
        }`;
        let data = new FormData();
        Object.entries(this.formData).forEach((item) => {
          if (
            (item[1] &&
              item[0] !== "image" &&
              item[0] !== "photo" &&
              item[0] !== "logo") ||
            item[1] === 0
          ) {
            if (Array.isArray(item[1])) {
              item[1].forEach((it) => data.append(`${item[0]}[]`, it));
            } else {
              data.append(item[0], item[1]);
            }
          } else if (
            item[1] &&
            (item[0] === "image" || item[0] === "photo" || item[0] === "logo")
          ) {
            data.append(item[0], item[1][0]);
          }
        });

        if (this.actionItem) {
          data.append("id", this.actionItem.id);
          data.append("tab_name", "basic");
        }

        ApiService.post(url, data)
          .then(({ data }) => {
            if (data.success) {
              if (data.customer) {
                this.$store.commit(SET_ITEM_FOR_ACTION, data.customer);
              }
              Swal.fire({
                title: this.actionItem ? "Updated" : "Created",
                text: "Successfully submitted",
                icon: "success",
                showConfirmButton: true,
              });
              this.refresher();
            } else {
              let editedItemMessage = `<div><ol>`;
              data.messages.forEach((item) => {
                editedItemMessage += `<li>${item}</li>`;
              });
              editedItemMessage = editedItemMessage + `</ol></div>`;
              Swal.fire({
                title: "Success",
                html: `${editedItemMessage}`,
                icon: data.error_orders === 0 ? "success" : "warning",
                showConfirmButton: true,
                // timer: 2500,
              });
            }
          })
          .finally(() => {
            this.pageLoader(false);
          });
      }
    },
    getFormData() {
      const {
        type_id,
        systemCode,
        staff_account_limit,
        spl_integration_type,
        name,
        email,
        update_order_status,
        shopCode,
        shopName,
        description,
        aggregator_id,
        shipping_label_name,
        contract_number,
        offer_type_id,
        sales,
        default_warehouse_id,
        organization_id,
        fulfillment_types,
        rvp_pickup_scheduled_mode,
        mrsal_code,
        instant_awb,
        instant_rvp_awb,
        consider_base_sku_price,
        won_date,
        sales_person,
        account_managers,
        services,
        expiry_pullout_threshold,
      } = this.serverData.customer;

      this.formData = {
        ...this.formData,
        type_id,
        systemCode,
        staff_account_limit,
        spl_integration_type,
        name,
        shopName,
        email,
        update_order_status,
        shopCode,
        description,
        aggregator_id,
        shipping_label_name,
        contract_number,
        offer_type_id,
        sales,
        default_warehouse_id,
        organization_id,
        fulfillment_types,
        rvp_pickup_scheduled_mode,
        mrsal_code,
        instant_awb,
        instant_rvp_awb,
        consider_base_sku_price,
        won_date,
        sales_person,
        account_managers,
        services,
        expiry_pullout_threshold,
      };
    },
    reset() {
      this.$v.$reset();
      this.formData = {
        type_id: null,
        aggregator_id: null,
        organization_id: null,
        name: null,
        system_code: null,
        shopName: null,
        email: null,
        update_order_status: null,
        shopCode: null,
        shipping_label_name: null,
        contract_number: null,
        offer_type_id: null,
        sales: [],
        default_warehouse_id: null,
        photo: null,
        logo: null,
        description: null,
        fulfillment_types: [],
        rvp_pickup_scheduled_mode: 0,
        mrsal_code: null,
        instant_awb: false,
        instant_rvp_awb: false,
        consider_base_sku_price: false,
        systemCode: null,
        won_date: null,
        sales_person: null,
        account_managers: null,
        services: [],
        expiry_pullout_threshold: 60,
      };
    },
    getDateRange(date) {
      this.formData.won_date = date[0];
    },
    cleardate() {
      this.formData.won_date = null;
    },
  },
  computed: {
    typeErrors() {
      return this.handleFormValidation("type_id", this);
    },
    organizationsErrors() {
      return this.handleFormValidation("organization_id", this);
    },
    nameErrors() {
      return this.handleFormValidation("name", this);
    },
    systemCodeErrors() {
      return this.handleFormValidation("systemCode", this);
    },
    emailErrors() {
      return this.handleFormValidation("email", this);
    },
    aggregatorErrors() {
      return this.handleFormValidation("aggregator_id", this);
    },
    staffAccountLimitErrors() {
      return this.handleFormValidation("staff_account_limit", this);
    },
    splIntegrationTypesErrors() {
      return this.handleFormValidation("spl_integration_type", this);
    },
    shopNameErrors() {
      return this.handleFormValidation("shopName", this);
    },
    shopCodeErrors() {
      return this.handleFormValidation("shopCode", this);
    },
    shippingLabelErrors() {
      return this.handleFormValidation("shipping_label_name", this);
    },
    divisions() {
      if (this.serverData.organizations) {
        return this.serverData.organizations.find(
          (item) => item.index === this.formData.organization_id
        )?.divisions;
      } else return [];
    },
    isOrganizationSPL() {
      return (
        this.$store.getters.currentUser.data.organization_system_code === "SPL"
      );
    },
  },
};
</script>

<style></style>
