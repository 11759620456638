<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" scrollable persistent max-width="1100px">
      <v-card class="sellers-actions-container poppins">
        <v-card-title
          class="d-flex justify-content-between h-65px border-bottom"
        >
          <h4 class="mb-0 font-weight-bolder">
            {{ this.$t(actionType) }} {{ this.$t("client") }}
          </h4>
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="toggleModal"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
        </v-card-title>
        <v-card-text>
          <v-container class="py-0">
            <!--            <v-card class="p-5 sellers-tab-card">-->
            <v-card flat class="d-flex flex-column elevation-0">
              <div class="d-flex flex-column flex-fill custom-tab-container">
                <!-- Basic fields starts -->
                <BasicFormFields
                  ref="basics"
                  :serverData="serverData"
                  :pageLoader="pageLoader"
                  :refresher="refresher"
                  :actionItem="actionItem"
                />
                <!-- Basic fields ends -->
              </div>
            </v-card>
            <!--            </v-card>-->
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import ApiService from "@/core/services/api.service";

import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import { SET_ITEM_FOR_ACTION } from "@/core/services/store/main.module";
import { UPDATE_TABLE_DATA } from "@/core/services/store/clients.module.js";

import BasicFormFields from "@/own/components/customers/clients/FormParts/BasicFormFields.vue";

export default {
  name: "ActionDialog",
  components: {
    BasicFormFields,
  },
  data: () => ({
    dialog: false,
    serverData: {},
    // activeTab: 0,
    actionType: "",
  }),
  methods: {
    toggleModal() {
      if (!this.dialog) {
        if (!this.actionItem) {
          this.loadServerData("create").then(() => {
            // this.activeTab = 0;
            this.dialog = true;
            this.actionType = "Create";
          });
        } else {
          this.loadServerData("edit").then(() => {
            // this.activeTab = 0;
            this.dialog = true;
            this.actionType = "Edit";
          });
        }
      } else {
        this.$store.commit(SET_ITEM_FOR_ACTION, null);
        this.dialog = false;
        this.reset();
      }
    },
    loadServerData(slug) {
      this.pageLoader(true);
      let payload = {
        tab_name: "basic",
      };

      if (this.actionItem) {
        payload.id = this.actionItem.id;
      }

      return ApiService.post(`authorizations/clients/${slug}`, payload)
        .then(({ data }) => {
          this.serverData = data;
        })
        .then(() => {
          if (this.actionItem) {
            setTimeout(() => {
              this.$refs.basics.getFormData();
            }, 50);
          }
        })
        .finally(() => {
          this.pageLoader(false);
        });
    },
    // onTabChange(tab) {
    //   // console.log(tab);
    //   setTimeout(() => {
    //     if (tab === "Sales") {
    //       this.$refs.sales.loadServerData();
    //     }
    //   });
    // },
    pageLoader(val = true) {
      this.$store.commit(SET_PAGE_LOADING, val);
    },
    reset() {
      this.serverData = {};
      // this.serverData = {
      //   basics: {
      //     divisions: [],
      //     client_types: [],
      //     aggregators: [],
      //   },
      // };

      //Reset forms validations
      this.$refs.basics.reset();
      // if (this.$refs["sales"]) this.$refs.sales.reset();
    },
    // isTabDisabled() {
    //   if (this.activeTab !== 0 && !this.actionItem) {
    //     return true;
    //   }
    //   return false;
    // },
    // isTabVisible(key) {
    //   if (key === "Sales") {
    //     return true; // Return true otherwise
    //   } else {
    //     return true; // Return true for all other keys
    //   }
    // },
    refresher() {
      this.$store.dispatch(UPDATE_TABLE_DATA, this.tableState);
    },
  },
  computed: {
    actionItem: function () {
      return this.$store.getters.getItemForAction;
    },
    // tabs: function () {
    //   const tabs = [
    //     {
    //       title: "Basic configuration",
    //       key: "Basics",
    //       icon: "home",
    //       hide: false,
    //       disabled: false,
    //     },
    //     {
    //       title: "Sales",
    //       key: "Sales",
    //       icon: "cart-outline",
    //       hide: false,
    //       disabled: !this.actionItem,
    //     },
    //   ];
    //
    //   return tabs.filter((tab) => !tab.hide);
    // },
    // organizationSystemCode: function () {
    //   return this.$store.getters.organizationSystemCode;
    // },
    tableState: function () {
      return this.$store.getters.getCLIENTTableState;
    },
  },
};
</script>

<style lang="scss">
.sellers-tab-card {
  border: 1px solid #f1f1f4 !important;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03) !important;
  // min-height: 560px;
}
.no-scrollbar {
  scrollbar-width: none;
}
.sellers-actions-container {
  .v-card__text {
    overflow: hidden !important;
  }
}
.custom-tab-container {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 150px) !important;
}
</style>
